import React from "react";

export default function AvatarInstructions() {
  return (
    <div className="relative flex flex-row w-auto  text-xl  ">
      <div className="flex flex-row w-1/2">
        <div className="relative after:absolute text-xl font-bold text-gray-500 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
          Create automatic virtual presenter like this:
          <ol className="relative z-10 flex flex-col gap-4 justify-between text-sm font-medium text-gray-500">
            <li className="flex items-center bg-white pt-6 p-2">
              <span className="h-6 w-6 mr-2 flex-shrink-0 rounded-full text-center text-[14px] text-white font-bold leading-6 bg-blue-dark ">
                1
              </span>
              <span className="ml-2 text-xl font-bold">Upload your PPT or PDF</span>
            </li>
            <li className="flex items-center bg-white p-2">
              <span className="h-6 w-6 mr-2 flex-shrink-0 rounded-full text-center text-[14px] text-white font-bold leading-6 bg-blue-dark ">
                2
              </span>
              <span className="ml-2 text-xl font-bold">
                Choose generate script with PresenterGPT OR from the Notes-section
              </span>
            </li>
            <li className="flex items-center bg-white p-2">
              <span className="h-6 w-6 mr-2 flex-shrink-0 rounded-full text-center text-[14px] text-white font-bold leading-6 bg-blue-dark ">
                3
              </span>
              <span className="ml-2 text-xl font-bold">Choose your avatar</span>
            </li>
            <li className="flex items-center bg-white p-2">
              <span className="h-6 w-6 mr-2 flex-shrink-0 rounded-full text-center text-[14px] text-white font-bold leading-6 bg-blue-dark ">
                4
              </span>
              <span className="ml-2 text-xl font-bold">Watch the preview and the video</span>
            </li>
            <li className="flex items-center bg-white p-2">
              <span className="h-6 w-6 mr-2 flex-shrink-0 rounded-full text-center text-[14px] text-white font-bold leading-6 bg-blue-dark ">
                5
              </span>
              <span className="ml-2 text-xl font-bold">Update the script if needed (optional)</span>
            </li>
          </ol>
        </div>
      </div>
      <div className="w-1/2 flex flex-row flex-wrap gap-2 ">
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/agnes.png"} />
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/alisha.png"} />
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/andy.png"} />
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/angela.png"} />
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/jonathan.png"} />
        <img style={{ maxHeight: 130 }} src={"https://app.automate.video/assets/avatar/jason.png"} />
      </div>
    </div>
  );
}
